<template>
  <div class="w-full border-b border-grayscale-20">
    <div
      class="flex justify-between items-center cursor-pointer py-4"
      @click="isActive = !isActive"
    >
      <p class="text-13 font-bold">
        {{ title }}
      </p>
      <i v-if="isActive" class="icon-up text-20" />
      <i v-else class="icon-down text-20" />
    </div>
    <div class="py-4" v-if="isActive">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const isActive = ref(props.active);
</script>
